import React from "react"
import Header from "./header"
import Footer from "./footer"
import "../sass/style.sass"

export default function Layout({ children }) {
  return (
    <div className="vh d-flx flex-dir-col w-100">
      <Header/>
      <main className="flex-1">
        {children}
      </main>
      <Footer/>
    </div>
  )
}