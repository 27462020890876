import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import disableScroll from "disable-scroll"
import roavaLogo from "../images/roava-logo.png";


const Header = () => {

  const [navOpen, navToggle] = useState(false)
  const toggleNav = () => {
    navToggle(!navOpen)
  }
  const closeMenu = () => {
    navToggle(false)
  }
  const mobileClasses = () => {
    let navClasses = "toggle-area none d-flx al-i-c"
    if (navOpen) {
      navClasses += " open"
      disableScroll.on()
    } else {
      disableScroll.off()
    }
    return navClasses
  }

  useEffect(() => {

    let popup;
    var allButtons = document.querySelectorAll('.register');
    var header = document.querySelector('.site-header');

    popup = window.typeformEmbed.makePopup('https://2e8pua88y3t.typeform.com/to/kxXhfSQb', {
      mode: 'drawer_right',          
      hideHeaders: true,
      hideFooter: true,
      onSubmit: function () {
        console.log('Typeform successfully submitted');
      }
    });

    for (var i = 0; i < allButtons.length; i++) {
      allButtons[i].addEventListener('click', function() {
        popup.open();
      });
    }

    const handleScroll = () => {
      if (window.pageYOffset > 140) {
        header.classList.add("stick");
      }else{
        header.classList.remove("stick");
      }
    }

    window.addEventListener("scroll", handleScroll);

  }, [])
    

  return (
    <header className="site-header fixed-to-top w-100 ">
      <div className="main-wrap wrapper-x py-h mx-auto">
        <nav className="d-flx j-c-sb fw-w al-i-c">
          <Link to="/" className="logo">
            <div className="logo-area d-flx al-i-c">
              <img src={roavaLogo} className="" alt="Roava Logo" />
            </div>
          </Link>
          <div className={mobileClasses()}>
            <button
              type="button"
              aria-label="menu"
              className="nav-menu"
              onClick={toggleNav}
            >
              <span className="bar1"></span>
              <span className="bar2"></span>
              <span className="bar3"></span>
            </button>
            <ul className="none main-nav d-flx al-i-c">
              <li className="my-h">
                <a
                  href="/"
                  className="tx-c d-blk py-h link"
                  onClick={closeMenu}
                >
                  Home
                </a>
              </li>
              <li className="my-h">
                <a
                  href="/#features"
                  className="tx-c d-blk py-h link"
                  onClick={closeMenu}
                >
                  Features
                </a>
              </li>
              {/* <li className="my-h">
                <Link
                  to="/faqs"
                  className="tx-c d-blk py-h link"
                  onClick={closeMenu}
                >
                  FAQs
                </Link> 
              </li> */}

            </ul>
          </div>
        </nav>
      </div>
    </header>
  )
}

export default Header
